/*
 * BuildDocs пользовательский интерфейс version 0.1.0
 *
 * Copyright © 2021 ООО АДАПТ info@acfs.spb.ru
 * You may use, distribute and modify this code under the
 * terms of the ООО АДАПТ license
 */

export enum ActForm {
  ActRegistrySigners = 'ActRegistrySigners',
  ADIS = 'ADIS',
  AIN = 'AIN',
  AISDN = 'AISDN',
  AGFO = 'AGFO',
  AGIG = 'AGIG',
  AGIKND = 'AGIKND',
  AGMIG = 'AGMIG',
  AGSP = 'AGSP',
  AGTU = 'AGTU',
  AGZS = 'AGZS',
  AIA = 'AIA',
  AIAV = 'AIAV',
  AIGE = 'AIGE',
  AIIO = 'AIIO',
  AIMM = 'AIMM',
  AINVP = 'AINVP',
  AISIE = 'AISIE',
  AISVKV = 'AISVKV',
  AITPG = 'AITPG',
  AIVVP = 'AIVVP',
  AIZT = 'AIZT',
  ANMO = 'ANMO',
  AO = 'AO',
  AOGR = 'AOGR',
  AOKTPZ = 'AOKTPZ',
  AOMR = 'AOMR',
  AOOK = 'AOOK',
  AOOR = 'AOOR',
  AOPGP = 'AOPGP',
  AOPMST = 'AOPMST',
  AOPNR = 'AOPNR',
  AOSR = 'AOSR',
  AOSRNEW = 'AOSRNEW',
  AROOKNEW = 'AROOKNEW',
  AOSRK = 'AOSRK',
  AOSRPE = 'AOSRPE',
  AOSRPK = 'AOSRPK',
  AOSRZU = 'AOSRZU',
  AOUS = 'AOUS',
  AOUSITO = 'AOUSITO',
  APESA = 'APESA',
  APFZ = 'APFZ',
  APIIAUP = 'APIIAUP',
  APITNP = 'APITNP',
  APKNSTRI = 'APKNSTRI',
  APKIAUP = 'APKIAUP',
  APLE = 'APLE',
  APOOK = 'APOOK',
  APOP = 'APOP',
  APOS = 'APOS',
  APOSNF = 'APOSNF',
  APOSNP = 'APOSNP',
  APPDT = 'APPDT',
  APPGI = 'APPGI',
  APPGINT = 'APPGINT',
  APPOM = 'APPOM',
  APPOVM = 'APPOVM',
  APPR = 'APPR',
  APRK = 'APRK',
  APSVVK = 'APSVVK',
  APTO = 'APTO',
  APTOL = 'APTOL',
  APTPMK = 'APTPMK',
  APTS = 'APTS',
  APUE = 'APUE',
  APUOF = 'APUOF',
  APVK = 'APVK',
  APVOT = 'APVOT',
  APVSHGV = 'APVSHGV',
  ARKPO = 'ARKPO',
  AROOK = 'AROOK',
  ATGEMR = 'ATGEMR',
  ATIS = 'ATIS',
  ATOV = 'ATOV',
  AVDP = 'AVDP',
  AVDTS = 'AVDTS',
  AZNGVL = 'AZNGVL',
  DS = 'DS',
  JMKM = 'JMKM',
  JPK = 'JPK',
  PF = 'PF',
  PIGRU = 'PIGRU',
  PISI = 'PISI',
  PISK = 'PISK',
  POPS = 'POPS',
  POSIB = 'POSIB',
  PPFL = 'PPFL',
  PPKBT = 'PPKBT',
  PPKNB = 'PPKNB',
  PPTDL = 'PPTDL',
  PVS = 'PVS',
  TitleList = 'TitleList',
  VMVLE = 'VMVLE',
  VSPKP = 'VSPKP',
  VSPOAUP = 'VSPOAUP',
  VSPSA = 'VSPSA',
  ZRDSL = 'ZRDSL',
  ZHOR = 'ZHOR',
  ZHVK = 'ZHVK',
  APZP = 'APZP',
  AODR = 'AODR',
  AGSML = 'AGSML',
  VIOP = 'VIOP',
  VSEO = 'VSEO',
  PZU = 'PZU',
  APOII = 'APOII',
  AIVPVR = 'AIVPVR',
  PSOTH = 'PSOTH',
  PIDL = 'PIDL',
  SMTT = 'SMTT',
  APSTSE = 'APSTSE',
  APVOSM = 'APVOSM',
  AIT = 'AIT',
  VZKRAB = 'VZKRAB',
  AGSGP = 'AGSGP',
  APOSPZ = 'APOSPZ',
  AGOMSA = 'AGOMSA',
  AORSA = 'AORSA',
  PSSM = 'PSSM',
  PRU = 'PRU',
  RMTSA = 'RMTSA',
  AITPPD = 'AITPPD',
  AITPPP = 'AITPPP',
  SMTP = 'SMTP',
  VSTSA = 'VSTSA',
  APSAE = 'APSAE',
  AVNL = 'AVNL',
  APUSDO = 'APUSDO',
  VPZP = 'VPZP',
  VPTSUO = 'VPTSUO',
  VPTPU = 'VPTPU',
  APSPM = 'APSPM',
  ANOO = 'ANOO',
  AVDO = 'AVDO',
  AVDA = 'AVDA',
  APOM = 'APOM',
  AITO = 'AITO',
  AIMO = 'AIMO',
  ARPK = 'ARPK',
  AOJ = 'AOJ',
  SCH = 'SCH',
  AKRA = 'AKRA',
  ATG = 'ATG',
  AVRK = 'AVRK',
  AVR_FSK = 'AVRFSK',
  APUOFT = 'APUOFT',
  VSO = 'VSO',
  PIKPKI = 'PIKPKI',
  AGOK = 'AGOK',
  AZA = 'AZA',
  APPTTS = 'APPTTS',
  AUFE = 'AUFE',
  AUD = 'AUD',
  APMTS = 'APMTS',
  APRZ = 'APRZ',
  APGI = 'APGI',
  APKPT = 'APKPT',
  AIP = 'AIP',
  APIZ = 'APIZ',
  APPT = 'APPT',
  APMT = 'APMT',
  APPI = 'APPI',
  AODSUP = 'AODSUP',
  APOPNR = 'APOPNR',
}

export enum ActFormDescription {
  AOSR = 'Акт освидетельствования скрытых работ',
  AVR_FSK = 'Акт выполненных работ(ФСК)',
  AOOK = 'Акт освидетельствования ответственных конструкций',
  AIIO = 'Акт индивидуального испытания оборудования',
  AOGR = 'Акт освидетельствования геодезической разбивочной основы объекта капитального строительства',
  APPR = 'Акт о проведении приёмочного гидравлического испытания безнапорного трубопровода на герметичность',
  APOOK = 'Акт промежуточного освидетельствования ответственных конструкций',
  APOS = 'Акт ПОС',
  AROOK = 'Акт разбивки осей объекта капитального строительства на местности',
  AOPGP = 'Акт освидетельствования (приёмки) готовых поверхностей',
  APPDT = 'Акт о проведении промывки и дезинфекции трубопроводов (сооружений) хозяйственно-питьевого водоснабжения',
  APPGINT = 'Акт о проведении приёмочного гидравлического испытания напорного трубопровода на прочность и герметичность',
  APZP = 'Акт приёмки защитного покрытия',
  AISVKV = 'Акт о проведении испытания систем канализации и водостоков',
  APTO = 'Акт промывки (продувки) системы',
  ActRegistrySigners = 'ActRegistrySigners',
  AGFO = 'Акт готовности фундаментов (оснований) к установке оборудования, насосов, компрессоров, ёмкостей, газовых батарей и т.д.',
  AGIG = 'Акт гидростатического испытания на герметичность',
  AGIKND = 'Акт гидростатического испытания котлов низкого давления (водоподогревателей, котельно-вспомогательного оборудования, мазутопровода)',
  AGSP = 'Акт готовности строительной части помещений (сооружений) к производству электромонтажных работ',
  AGTU = 'Акт готовности траншей к укладке трубопроводов (кабелей)',
  AGZS = 'Акт готовности зданий, сооружений к производству монтажных работ',
  AINVP = 'Акт испытаний на водоотдачу наружного противопожарного водопровода',
  AIVVP = 'Акт испытаний на водоотдачу внутреннего противопожарного водопровода',
  ANMO = 'Акт на монтаж оборудования',
  AOKTPZ = 'Акт осмотра канализации из труб перед закрытием',
  AOMR = 'Акт об окончании монтажных работ',
  AOOR = 'Акт осмотра открытых рвов и котлованов под фундаменты',
  AOPMST = 'Акт о приёмке в монтаж силового трансформатора*',
  AOUSITO = 'Акт освидетельствования участков сетей инженерно-технического обеспечения',
  APPGI = 'Акт о проведении приёмочного гидравлического испытания ёмкостного сооружения на водонепроницаемость (герметичность)',
  ATIS = 'Акт теплового испытания системы центрального отопления на эффект действия',
  ZHOR = '(ЖОР) Общий журнал работ',
  ZHVK = '(ЖВК) Журнал входного учёта и контроля качества получаемых деталей, материалов, конструкций и оборудования',
  AGMIG = 'Акт о проведении гидростатического или манометрического испытания на герметичность',
  APVK = 'Акт о проведении входного контроля',
  AGSML = 'Акт готовности строительной части к производству работ по монтажу лифта',
  AODR = 'Акт освидетельствования демонтажных работ',
  AOSRK = 'Акт освидетельствования скрытых работ (канализация)',
  AOSRPE = 'Акт освидетельствования скрытых работ по прокладке электропроводок по стенам, потолкам, в полу',
  APITNP = 'Акт о проведении испытаний трубопроводов на прочность и герметичность',
  AOUS = 'УДАЛЁН. То же, что AOUSITO',
  APPOVM = 'Акт приёмки-передачи оборудования в монтаж',
  APPOM = 'Акт приёмки-передачи оборудования в монтаж ОС-15',
  PISI = 'Протокол измерения сопротивления изоляции',
  ATGEMR = 'Акт технической готовности электромонтажных работ',
  VIOP = 'Ведомость изменений и отступлений от проекта',
  VSEO = 'Ведомость смонтированного электрооборудования',
  POSIB = 'Протокол осмотра и проверки изоляции кабелей, на барабане перед прокладкой',
  PPKBT = 'Протокол прогрева кабелей на барабане перед прокладкой при низких температурах',
  PZU = 'Паспорт заземляющего устройства',
  ADIS = 'Акт динамического испытания сваи',
  AISDN = 'Акт испытания сваи динамической нагрузкой Ф-35',
  AIA = 'Акт испытания арматуры',
  AIAV = 'Акт испытания агрегатов вхолостую или под нагрузкой',
  AIGE = 'Акт испытания гидропневматической ёмкости',
  AIMM = 'Акт испытания машин и механизмов',
  AISIE = 'Акт измерения сопротивления изоляции электропроводок',
  AITPG = 'Акт испытания трубопроводов на прочность и герметичность',
  AIZT = 'Акт испытания защитных труб с разделительными уплотнителями на герметичность',
  AO = 'Акт обследования',
  AOPNR = 'Акт об окончании пусконаладочных работ',
  AOSRPK = 'Акт освидетельствования скрытых работ (прокладка кабельных линий в земле)',
  AOSRZU = 'АКТ освидетельствования скрытых работ по монтажу заземляющих устройств',
  APESA = 'Акт приёмки в эксплуатацию систем автоматизации',
  APFZ = 'Акт приёмки фасадов здания',
  APIIAUP = 'Акт проведения индивидуальных испытаний автоматических установок пожаротушения',
  APKNSTRI = 'Акт приемки конструкций',
  APLE = 'Акт приёмки лифта в эксплуатацию',
  APOP = 'Акт приёмки оборудования после комплексного опробования',
  APOSNF = 'Акт проверки осветительной сети на функционирование и правильность монтажа установочных аппаратов',
  APOSNP = 'Акт проверки осветительной сети на правильность зажигания внутреннего освещения',
  APRK = 'Акт о проведении растяжки компенсаторов',
  APSVVK = 'Акт приёмки системы и выпусков внутренней канализации',
  APTOL = 'Акт полного технического освидетельствования лифта',
  APTPMK = 'Акт приёмки траншей, каналов, туннелей и блоков под монтаж кабелей',
  APTS = 'Акт о приёмке технических средств сигнализации в эксплуатацию',
  APUE = 'Акт приёмки установки в эксплуатацию',
  APUOF = 'Акт проверки установки оборудования на фундамент',
  APVOT = 'Акт проверки внутренней очистки трубопроводов',
  APVSHGV = 'Акт приёмки внутренних систем холодного и горячего водоснабжения',
  ARKPO = 'Акт рабочей комиссии о приёмке оборудования после индивидуального испытания',
  ATOV = 'Акт технического освидетельствования водомерного узла',
  AVDP = 'Акт о выявленных дефектах приборов, оборудования и агрегатов автоматической установки пожаротушения',
  AVDTS = 'Акт о выявленных дефектах в технических средствах сигнализации',
  AZNGVL = 'Акт замеров в натуре габаритов от проводов ВЛ до пересекаемого объекта',
  DS = 'Декларация о соответствии',
  JMKM = 'Журнал монтажа кабельных муфт напряжением выше 1000 В',
  JPK = 'Журнал прокладки кабелей',
  PF = 'Протокол фазировки',
  PIGRU = 'Протокол испытания на герметичность разделительных уплотнений защитных трубопроводов для электропроводок во взрывоопасных зонах',
  PISK = 'Протокол испытания силового кабеля напряжением выше 1000В',
  POPS = 'Протокол осмотра и проверки смонтированного электрооборудования распределительных устройств и электрических подстанций напряжением до 35 кВ включительно',
  PPFL = 'Протокол проверки функционирования лифта',
  PPKNB = 'Протокол прогрева кабелей на барабанах',
  PPTDL = 'Протокол проверки технической документации на лифт',
  PVS = 'Паспорт вентиляционной системы (системы кондиционирования воздуха)',
  TitleList = 'Титульный лист “Исполнительная документация”',
  VMVLE = 'Ведомость монтажа воздушной линии электропередачи',
  VSPKP = 'Ведомость смонтированных приёмно-контрольных приборов, сигнально-пусковых устройств, извещателей, оповещателей, технических средств ПОС',
  VSPOAUP = 'Ведомость смонтированных приборов и оборудования автоматической установки пожаротушения',
  VSPSA = 'Ведомость смонтированных приборов и средств автоматизации',
  ZRDSL = 'Заявление о регистрации декларации о соответствии лифта требованиям технического регламента "О безопасности лифта" ',
  PIDL = 'Протокол испытаний давлением локальных разделительных уплотнений или стальных труб для проводок во взрывоопасных зонах классов В-1 И В-1а',
  APOII = 'Акт приёмки оборудования после индивидуального испытания',
  AIVPVR = 'Акт испытания внутреннего противопожарного водопровода на работоспособность',
  PSOTH = 'Паспорт системы отопления, теплоснабжения и холодоснабжения',
  APVOSM = 'Акт проверки внешним осмотром сварочных материалов',
  APSTSE = 'Акт проверки сварочно-технологических свойств электродов',
  SMTT = 'Свидетельство о монтаже технологического трубопровода',
  AIT = 'АКТ испытания трубопроводов',
  VZKRAB = 'Ведомость замеров при контрольном разряде аккумуляторной батареи',
  AGSGP = 'Акт о готовности сетей газопотребления и газоиспользующего оборудования объекта капитального строительства к подключению (технологическому присоединению)',
  APOSPZ = 'Акты проверки осветительной сети на правильность зажигания внутреннего освещения',
  AGOMSA = 'Акт готовности объекта к производству работ по монтажу систем автоматизации',
  AORSA = 'Акт об окончании работ по монтажу систем автоматизации',
  PSSM = 'Паспорт на смонтированную соединительную муфту',
  PRU = 'Паспорт регенерационного участка',
  RMTSA = 'Разрешение на монтаж технических средств автоматизации',
  AITPPD = 'Акт испытания трубных проводок на герметичность с определением падения давления за время испытания',
  AITPPP = 'Акт испытания трубных проводок на прочность и плотность',
  SMTP = 'Свидетельство о монтаже трубных проводок',
  VSTSA = 'Ведомость смонтированных технических средств автоматизации',
  APSAE = 'Акт приёмки систем автоматизации в эксплуатацию',
  AVNL = 'Акт выявленных несоответствий лифта',
  APUSDO = 'Акт пробного уплотнения земляного полотна',
  VPZP = 'Ведомость приёмки земляного полотна',
  VPTSUO = 'Ведомость промеров толщины, степени уплотнения оснований',
  VPTPU = 'Ведомость промеров толщины, поперечных уклонов, ширины и ровности покрытий',
  APSPM = 'Акт приёмки сооружения (помещения) под монтаж',
  ANOO = 'Акт наружного осмотра оборудования (арматуры)',
  AVDO = 'Акт о выявленных дефектах оборудования',
  AVDA = 'АКТ о выявленных дефектах арматуры',
  APOM = 'Акт на приёмку оборудования в монтаж',
  AITO = 'Акт испытаний технологического оборудования',
  AIMO = 'Акт испытаний машинного оборудования',
  ARPK = 'Акт о регулировке предохранительного клапана',
  AOJ = 'Акт обезжиривания',
  SCH = 'Сертификат чистоты',
  AKRA = 'Акт контроля расположения аппаратов в блоке (оборудования криогенной системы)',
  AGOK = 'Акт готовности опорных конструкций под монтаж оборудования (коммуникаций)',
  AVRK = 'Акт на выверку ректификационной колонны',
  AZA = 'Акт загрузки адсорбента (реагента)',
  AUFE = 'Акт установки фильтрующих элементов',
  AUD = 'Акт на установку диафрагмы',
  APRZ = 'Акт на проведение работ по заземлению',
  APGI = 'Акт о проведении гидравлического испытания трубопровода (общего гидравлического испытания трубопроводной сети)',
  ATG = 'Акт технической готовности',
  APUOFT = 'Акт проверки установки оборудования на фундамент',
  VSO = 'Ведомость смонтированного оборудования',
  PIKPKI = 'Протокол испытаний клапанов пожарных кранов на исправность',
  ATO = 'Акт о теплой опрессовке (сравнительной теплой опрессовке)',
  AP = 'Акт продувки',
  AHO = 'Акт о холодной опрессовке',
  AGI = 'Акт готовности к изолированию',
  AI = 'Акт на изолирование',
  APTIPKO = 'Акт рабочей комиссии о приемке оборудования технического изделия после комплексного опробования',
  APTIPSI = 'Акт рабочей комиссии о приемке технического изделия после приемо-сдаточных испытаний',
  ARK = 'Акт растяжки компенсаторов',
  APKPT = 'APKPT',
  APKIAUP = 'Акт проведения комплексных испытаний автоматической установки пожаротушения',
  AIP = 'AIP',
  AIPPD = 'Акт испытания на плотность (перепуск давления из одной системы в другую и падение давления)',
  APIZ = 'APIZ',
  APIZKSH = 'Акт о проведении испытания замыкающих кольцевых швов трубопроводов (трубопроводных сетей)',
  APPT = 'APPT',
  APPTTS = 'Акт промывки/продувки трубопровода (трубопроводной сети)',
  APMT = 'APMT',
  APMTS = 'Акт проверки монтажа трубопроводов на соответствие монтажно-технологической схеме',
  APPI = 'APPI',
  APPITO = 'Акт о проведении пневматического испытания трубопровода (общего пневматического испытания трубопроводной сети)',
  AODSUP = 'Акт (освидетельствования выполненных работ по монтажу дополнительной системы уравнивания потенциалов)',
  APOPNR = 'Акт передачи смонтированного оборудования в производство пуско-наладочных работ',
}

// TODO: добавить все оставшиеся акты без заголовка
export const ActsWithoutHeaders = new Set<keyof typeof ActForm>([
  'AGSP',
  'ANMO',
])
