import { RestAPI, UUID } from '@builddocs/data-management'
import { unmappedWork } from './techzor.types'

export const getUnmappedWorks = (contractId: UUID):Promise<unmappedWork> =>
  RestAPI.get(`/techzor-acceptance/contracts/${contractId}/unmapped`).then(res => res.data)
export const getMappedWorks = (contractId: UUID) =>
  RestAPI.get(`/techzor-acceptance/contracts/${contractId}`).then(res => res.data)
export const compareWorks = (data:{buildDocsWorkId:string, externalWorkId:string}[]) =>
  RestAPI.patch('/techzor-acceptance/contracts',data).then(res=>res)
export const sendOnAcceptance = (data:string[]) =>
  RestAPI.post(`/techzor-acceptance/`, data).then(res => res)
export const unmapWork = (data:{buildDocsWorkId:string, externalWorkId:string}) =>
  RestAPI.patch(`/techzor-acceptance/contracts/unmap`, data).then(res => res)
export const getOnAcceptanceWorks = (contractId: UUID) =>
  RestAPI.get(`/techzor-acceptance/contracts/${contractId}/on-acceptance?pageNumber=0`).then(res => res.data)

export const getOnAcceptanceWorksPaginated = (contractId: UUID, page:number, size=4) =>
  RestAPI.get(`/techzor-acceptance/contracts/${contractId}/on-acceptance/paged?page=${page}&size=${size}`).then(res => res.data)
