import {
  ExternalSigningAct,
  ExternalSigningApplication,
  externalSigningData,
} from '@builddocs/ext-sign-data-access'
import { Box, Stack, Typography } from '@mui/material'
import { useRecoilState } from 'recoil'

import BuildDocsLogo from '../assets/BD icon gravity gray.svg'

import { HeaderContent } from './HeaderContent'

type Props = {
  type: 'ACT' | 'APPLICATION' | false
}

export const ExtSignHeader = ({ type }: Props) => {
  const [externalSigningValue] = useRecoilState<
    ExternalSigningAct | ExternalSigningApplication | undefined
  >(externalSigningData)

  return (
    <Stack alignItems="center" direction="row" px={3} py={1} spacing={1}>
      <Box
        alt="BuildDocs"
        component="img"
        src={BuildDocsLogo}
        sx={{
          userSelect: 'none',
          marginLeft: 1.5,
          fill: 'black',
        }}
        width="130px"
      />
      {type === 'ACT' && (
        <HeaderContent
          externalSigningValue={externalSigningValue as ExternalSigningAct}
        />
      )}
      {type === 'APPLICATION' && (
        <Box width="100%">
          <Typography align="center">
            Пожалуйста выберите действия для данного документа
          </Typography>
        </Box>
      )}
    </Stack>
  )
}
