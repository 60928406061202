import { useEffect } from 'react'

import { ActKsInfo, WorkType } from '@builddocs/data-management'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery, useQueryClient } from 'react-query'

import { FilestoreAPI } from '../FilestoreAPI'

import { ResponseClosingType } from './closings'

interface ClosingsProps {
  contractId: string
  workDocSection: string
  workType?: WorkType
  closingId?: string | undefined
  startDate?: string
  endDate?: string
  attachedKsActs?: ActKsInfo[]
}

interface IClosingsHookResponse {
  closings: ResponseClosingType[]
  refreshClosings: () => void
  isLoading: boolean
  error?: unknown
  isError?: boolean
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  refetch: () => void
  fetchNextPage: () => void
  totalElements: number
  ref: (node?: Element | null | undefined) => void
}
const fetchClosings = async (
  { pageParam = 0 },
  data: {
    journalId: string
    workDocSection: string
    workType?: WorkType
    startDate?: string
    endDate?: string
    closingId?: string
    pageParam: string
    attachedKsActs?: ActKsInfo[]
  },
) => {
  const { closingId, ...formedData } = data
  if (closingId?.length){
    const response = await FilestoreAPI.post(
      `/closing/search/paged?page=${pageParam}&size=15`,
      data,
    )

    return response.data
  } else {

    const response = await FilestoreAPI.post(
      `/closing/search/paged?page=${pageParam}&size=15`,
      formedData,
    )

    return response.data
  }

}

export const useClosings = (props: ClosingsProps, closingId:string | undefined): IClosingsHookResponse => {
  const queryClient = useQueryClient()
  const {
    contractId: journalId,
    workDocSection,
    workType,
    startDate,
    endDate,
    attachedKsActs,
  } = props

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    refetch,
    error,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [
      'closings',
      journalId,
      workDocSection,
      workType,
      startDate,
      endDate,
      attachedKsActs,
    ],
    ({ pageParam = 0 }) =>
      fetchClosings(
        { pageParam },
        {
          journalId,
          workDocSection,
          workType,
          startDate,
          endDate,
          closingId,
          attachedKsActs,
          pageParam,
        },
      ),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage.last) {
          return pages.length // Assuming the API returns zero-based page index
        }
        return undefined
      },
    },
  )

  const { ref, inView } = useInView()

  const refreshClosings = () => {
    queryClient.invalidateQueries([
      'closings',
      journalId,
      workDocSection,
      workType,
      startDate,
      endDate,
      attachedKsActs,
    ])
  }

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, fetchNextPage, isFetchingNextPage])

  // Flatten pages of closings into a single array
  const closings = data?.pages.flatMap(page => page.content) ?? []
  const totalElements = data?.pages[0].totalElements ?? 0

  return {
    closings,
    isLoading,
    error,
    isError,
    totalElements,
    refreshClosings,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    ref, // This ref should be attached to the scrolling container's sentinel
  }
}
