import { ActForm, WorkType, UUID, ActKsInfo } from '@builddocs/data-management'
import fileDownload from 'js-file-download'

import { AOSRType, SecondaryActType } from '../acts'
import { ActType } from '../acts/actType.types'
import { FilestoreAPI } from '../FilestoreAPI'

import {
  DataClosingType,
  ResponseClosingType,
  TemplatesForChange,
} from './closings'

/** Создание закрытия*/
export const createClosing = (data: DataClosingType) =>
  FilestoreAPI.post(`/closing`, {
    journalId: data.contractId,
    workDocSection: data.workDocSection,
    startDate: data.startDate,
    endDate: data.endDate,
    acts: data.acts,
    enumActs: data.secondaryActs,
    secondaryActs: data.secondaryActsOutsideClosing,
  })

/** Получить список закрытий*/
export const getClosingsList: (data: {
  journalId: string
  workDocSection: string
  workType?: WorkType
  startDate?: string
  endDate?: string
  pageParam: string
  attachedKsActs?: ActKsInfo[]
}) => Promise<ResponseClosingType[]> = data =>
  FilestoreAPI.post(`/closing/search/paged?page=${0}&size=5`, data).then(
    res => res.data || [],
  )

/** Получение Закрытия по Id*/
export const getClosingById: (closingId: UUID) => Promise<ResponseClosingType> =
  Id => FilestoreAPI.get(`/closing/${Id}`).then(res => res.data)

/** Удалить закрытие*/
export const deleteClosing = (closingId: UUID) =>
  FilestoreAPI.delete(`/closing/${closingId}`)

/** Изменить закрытие*/
export const updateClosing = (
  closingId: UUID,
  acts: Record<WorkType, UUID[]>,
) => FilestoreAPI.put(`/closing/${closingId}`, acts)

/** Скачать закрытие*/
export const downloadClosing = (closingId: UUID) =>
  FilestoreAPI.get(`/closing/${closingId}/download-archive`, {
    responseType: 'blob',
  }).then(res => {
    return fileDownload(res.data, res.headers['content-disposition'].slice(20))
  })

/** Проверка возможности создания закрытия раздела*/
export const checkSectionClosing = (data: {
  journalId: UUID
  workDocSection: string
}) =>
  FilestoreAPI.get(`/final-closing/check`, {
    params: { journalId: data.journalId, workDocSection: data.workDocSection },
  }).then(res => res.data)

/** Создание закрытия раздела*/
export const createSectionClosing = (data: {
  creatorId: UUID
  journalId: UUID
  workDocSection: string
}) => FilestoreAPI.post(`/final-closing`, data)

/** Получение закрытия раздела*/
export const getSectionClosing = (data: {
  journalId: UUID
  workDocSection: string
}) =>
  FilestoreAPI.get(`/final-closing`, {
    params: { journalId: data.journalId, workDocSection: data.workDocSection },
  }).then(res => res.data)

/** Удаление закрытия раздела*/
export const deleteSectionClosing = (data: {
  journalId: UUID
  workDocSection: string
}) =>
  FilestoreAPI.delete(`/final-closing`, {
    params: { journalId: data.journalId, workDocSection: data.workDocSection },
  })

/** Получение списка секондари-актов по виду работ для закрытия*/
export const getSecondaryActsAbbreviationsByWorkType = (
  workType: WorkType,
): Promise<ActType[]> =>
  FilestoreAPI.get(`/secondary-act/abbreviations-with-name/${workType}`).then(
    res => (res.status === 204 ? [] : res.data),
  )

/** Обновление шаблонов нумерации актов*/
export const updateTemplatesActNumbers = (
  closingId: UUID,
  templates: TemplatesForChange,
) =>
  FilestoreAPI.put(`/closing/${closingId}/change-number-templates`, templates)

/** Добавление актов в существующее закрытие*/
export const addActsToClosing = (
  data: {
    acts: Record<string, any>
    secondaryActsOutsideClosing: Record<string, any>
  },
  enumActs: Record<string, string[]>,
  closingId: UUID,
) =>
  FilestoreAPI.put(`/closing/${closingId}/add-acts`, {
    acts: data.acts,
    enumActs,
    secondaryActs: data.secondaryActsOutsideClosing,
  })

/** Исключить акты из закрытия*/
export const RemoveActsFromClosing = (
  closingId: UUID,
  act: AOSRType | SecondaryActType,
) =>
  FilestoreAPI.put(
    `/closing/${closingId}/remove-acts`,
    act.actType === ActForm.AOSR
      ? { acts: [act.id] }
      : { secondaryActs: [act.id] },
  )

export const attachKSToClosing = (closingId: UUID, data: ActKsInfo[]) => {
  return FilestoreAPI.put(`/closing/${closingId}/attach-ks-act`, data)
}
