import { useQuery, useQueryClient } from 'react-query'
import { acceptanceWork, getOnAcceptanceWorksPaginated } from '@builddocs/data-management'


interface IProjectHook {
  isLoading: boolean
  acceptanceWorks: undefined | acceptanceWork[]
  error?: unknown
  totalPages: string
  isError?: boolean
  refreshWorks: () => void
  currentPage: string
  isFetching: boolean
}

export const useComparedPaginatedWorks = (contractId: string, page:number): IProjectHook => {
  const queryClient = useQueryClient()
  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery(['acceptanceWorksPaginated', contractId, page], async () =>
    contractId ? await getOnAcceptanceWorksPaginated(contractId, page) : undefined,
    { keepPreviousData: true }

  )

  const acceptanceWorks = data?.content;
  const totalPages = data?.totalPages;
  const currentPage = data?.number;

  const refreshWorks = () =>
    queryClient.invalidateQueries(['acceptanceWorksPaginated', contractId, page])

  return {
    acceptanceWorks,
    isLoading,
    error,
    isError,
    refreshWorks,
    isFetching,
    totalPages,
    currentPage,
  }
}

export default useComparedPaginatedWorks
